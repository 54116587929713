import React, {useState, useEffect} from 'react';
import Aco from './images/aleksander.webp'
import Youtube from './images/youtube.svg'
import AudioPlayer from 'react-h5-audio-player';
import Blohmsoft from './images/blohmsofticon.webp'
import 'react-h5-audio-player/lib/styles.css';
import './css/About.css'
import { useTranslation } from 'react-i18next'

function About() {
  let [tracks, setTracks] = useState([])
  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL,{method : "GET"})
      .then(response => response.json())
      .then(data => setTracks(data))
      .catch(function(error) {
        console.log('There has been a problem with your fetch operation: ' + error.message);
      })
    },[])

  const { t } = useTranslation();
    return (
      <section id="about">
        <div id="info">
          <div id="infoz">
            <div id="whoAleks">{t('whoIs')} 🤷🏻‍♂</div>
            <div>{t('bornSweden')}</div>
            <div>{t('sport')}</div>
            <div>{t('creator')} <a href="https://www.youtube.com/c/CryptoSportsAleksanderPantić" target="_blank" rel="noopener noreferrer">{t('cryptoSports')} <img alt="" className="fakeEmoji" src={Youtube}/></a>.</div>
            <div>{t('systemDeveloper')} <a href="https://blohmsoft.se/" target="_blank" rel="noopener noreferrer">{t('Blohmsoft')} <img alt="" className="fakeEmoji" src={Blohmsoft}/></a>.</div>
          </div>

          <div id="wrapps">
            <h3>{t('tracks')}</h3>
            <div id="playlist">
                {tracks.map(item => (
                  <div id="track">
                    <div id="trackOrder">{item.trackOrder}</div>
                    <img src={item.trackImage} id="trackImage" alt="new"/>
                    <div id="trackDiv">
                      <a href={item.trackLink} target="_blank" rel="noopener noreferrer">
                        <div id="trackName">{item.trackName}</div>
                      </a>
                      <div id="trackArtists">{item.trackArtists}</div>
                    </div>
                    <AudioPlayer src={item.trackPreview} volume={0.5} showJumpControls={false} autoPlayAfterSrcChange={false} loop={false} customAdditionalControls={[]} customVolumeControls={[]} layout="stacked-reverse" customProgressBarSection={[]}/>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div id="acoBundle">
          <img id="aco" src={Aco} alt=""/>
          <h3>“{t('familyGuy')}”</h3>
          <h5>{t('theBoys')}</h5>
        </div>
      </section>
    )
}
export default About;