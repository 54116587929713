import React, {Suspense} from 'react';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import "./i18n";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <Suspense fallback="Loading..." >
      <App/>
    </Suspense>
  </StrictMode>
);

reportWebVitals();