import React from 'react'
import './css/App.css';
import Start from './Start';
import About from "./About";
import Experience from "./Experience";
import { useTranslation } from 'react-i18next'
import "./i18n";
import i18n from 'i18next'
import aleksLogo from './images/aleksLogo.svg'
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";

function App (){
  const navSlide = () => {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.navbar ul');
    const navLinks = document.querySelectorAll('.nav-links li')

    nav.classList.toggle('nav-active');

    navLinks.forEach((link,index)=>{
      link.style.animation = `navLinkFade 0.5s ease forwards ${index / 5 + 1}s`;
    });
    burger.classList.toggle('toggle');
  }
  const changeLanguages = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const { t } = useTranslation();
    return (
        <div className="App">
            <body>
              <div className="container">
                <nav className="navbar">
                  <img alt="" src={aleksLogo}/>
                  <ul className="nav-links">
                    <li key="start"><a href="#start" onClick={navSlide}>{t('start')}</a></li>
                    <li key="about"><a href="#about" onClick={navSlide}>{t('about')}</a></li>
                    <li key="experience"><a href="#experience" onClick={navSlide}>{t('experience')}</a></li>
                    <li key="language" id="language" className="select-container">
                      <select onChange={changeLanguages} className="select">
                        <option value="en">🇬🇧 english</option>
                        <option value="sv">🇸🇪 svenska</option>
                        <option value="sr">🇷🇸 српски</option>
                      </select>
                    </li>
                  </ul>

                  <select id="language2" onChange={changeLanguages}>
                    <option value="en">🇬🇧 ENGLISH</option>
                    <option value="sv">🇸🇪 SVENSKA</option>
                    <option value="sr">🇷🇸 СРПСКИ</option>
                  </select>

                  <button onClick={navSlide} className="burger">
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                  </button>
                </nav>
                <Start/>
                <About/>
                <Experience/>
              </div>
            </body>
        </div>
    );
}
polyfillCountryFlagEmojis();
export default App;