import React from 'react'
import { VerticalTimeline, VerticalTimelineElement, } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import './css/Experience.css'
import {useTranslation} from "react-i18next";
import {ReactComponent as School} from './images/school.svg'
import {ReactComponent as Work} from './images/work.svg'

function Experience (){
    const { t } = useTranslation();
    return (
      <section id="experience">
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ 'background': '#3772ff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date={t('octNu')}
            iconStyle={{ 'background': '#3772ff'}}
            icon={<Work/>}
          >
            <h3 className="vertical-timeline-element-title">{t('systemDeveloper2')}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t('Blohmsoft')}</h4>
            <p>{t('integrations')}</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ 'background': '#3772ff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date={t('augOct')}
            iconStyle={{ 'background': '#3772ff'}}
            icon={<School/>}
          >
            <h3 className="vertical-timeline-element-title">{t('softwareMaster')}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t('chalmers')}</h4>
            <p>{t('master')}</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ 'background': '#3772ff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date={t('augAug')}
            iconStyle={{ 'background': '#3772ff'}}
            icon={<School/>}
          >
            <h3 className="vertical-timeline-element-title">{t('school')}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t('chalmers')}</h4>
            <p>{t('bachelor')}</p>
          </VerticalTimelineElement>
        </VerticalTimeline>
        <div id="CV">{t('cvLink')} <a href="https://drive.google.com/file/d/1q3188pE9L-tR61Gkl_UmwGV-ufmGegxi/view?usp=sharing" target="_blank" rel="noopener noreferrer">CV</a> 📝</div>
      </section>
    )
}
export default Experience;