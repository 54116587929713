import i18n from "i18next";
import { initReactI18next} from 'react-i18next';
import EN from "./translations/en.json";
import SV from "./translations/sv.json";
import SR from "./translations/sr.json";

const resources = {
  en: {
    translation: EN
  },
  sv: {
    translation: SV
  },
  sr: {
    translation: SR
  }
}
i18n.use(initReactI18next)
  .init({
      resources,
      lng: "en",
      fallbackLng: "en",
      interpolation: { escapeValue: false }
    })

export default i18n;