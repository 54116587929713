import './css/Start.css'
import React from 'react'
import cartoon from './images/cartoonAleks.webp'
import crypto from './images/crypto.svg'
import linkedin from './images/linkedIn.svg'
import { useTranslation } from 'react-i18next'

function Start(){
  const { t } = useTranslation();
    return (
      <section id="start">
        <div id="hello">
          <h1>{t('hello')} <span className="wave">👋</span> {t('iAm')}</h1>
          <img alt="" src={cartoon}></img>
        </div>
        <a id="linkedin" href="https://se.linkedin.com/in/aleksander-pantic" target="_blank" rel="noopener noreferrer">
          <img alt="" src={linkedin}/>
        </a>
        <a id="crypto" href="https://www.youtube.com/c/CryptoSportsAleksanderPantić" target="_blank" rel="noopener noreferrer">
          <img alt="" src={crypto}></img>
        </a>
      </section>
    )
}
export default Start;